import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MenuItems } from '@shared/menu-items/menu-items';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private menu: MenuItems) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // this.store.pipe(
    //   select(canActivateRoute, { route: state.url, menuItems: this.menu.getMenuitem() }),
    //   tap((canActivate) => {
    //     if (!Swal.isLoading)
    //       if (!canActivate)
    //         this.router.navigateByUrl('404')
    //   })
    // )
  }

}
