import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class UtcDatePipe extends DatePipe {

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale)
  }

  transform(value: any, format?: string, timezone: string = 'UTC', locale?: string): any {
    return super.transform(value, format, timezone, locale)
  }

}
