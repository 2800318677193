import { Component } from '@angular/core';
import { environment } from 'environments/environment';

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class AppBlankComponent {
  buildNo: string | number = environment.buildNo;
  year = new Date().getFullYear()
}
