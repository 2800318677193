import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {

  private counter: number = 0;
  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  $state: Observable<number> = this.loaderSubject.asObservable();

  constructor() { }

  increment() {
    this.counter++;
    this.loaderSubject.next(this.counter);
  }

  decrement() {
    this.counter--;
    this.loaderSubject.next(this.counter);
  }
}
