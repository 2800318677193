import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Separator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
}

const MENUITEMS: Menu[] = [
  {
    state: '',
    name: 'Main',
    type: 'separator',
    icon: ''
  },
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'pm',
    name: 'PM',
    type: 'link',
    icon: 'track_changes',
  },
  {
    state: 'condition-reports',
    name: 'Condition Reports',
    type: 'link',
    icon: 'fact_check',
  },
  {
    state: 'reports',
    name: 'Reports',
    type: 'link',
    icon: 'calendar_today',
  },
  {
    state: 'cases',
    name: 'Cases',
    type: 'link',
    icon: 'handyman',
  },
  {
    state: 'accidents',
    name: 'Accidents',
    type: 'link',
    icon: 'local_fire_department',
  },

  {
    state: 'pitstops',
    name: 'Pitstops',
    type: 'link',
    icon: 'car_repair',
  },
  // {
  //   state: 'contacts',
  //   name: 'Contacts',
  //   type: 'link',
  //   icon: 'people'
  // },
  {
    state: 'contactables',
    name: 'Contacts',
    type: 'link',
    icon: 'business'
  },
  // {
  //   state: 'companies',
  //   name: 'Companies',
  //   type: 'link',
  //   icon: 'business'
  // },
  // {
  //   state: 'towing-companies',
  //   name: 'Towing Companies',
  //   type: 'link',
  //   icon: 'business'
  // },
  // {
  //   state: 'shops',
  //   name: 'Shops',
  //   type: 'link',
  //   icon: 'home_repair_service',
  // },
  {
    state: '',
    name: 'Fleet Management',
    type: 'separator',
    icon: ''
  },
  {
    state: 'fleet-overview',
    name: 'Fleet overview',
    type: 'link',
    icon: 'commute'
  },
  {
    state: 'agreements',
    name: 'Agreements',
    type: 'link',
    icon: 'chrome_reader_mode'
  },
  {
    state: 'trucks',
    name: 'Trucks',
    type: 'link',
    icon: 'commute'
  },
  {
    state: 'drivers',
    name: 'Drivers',
    type: 'link',
    icon: 'assignment_ind'
  },
  {
    state: 'charges',
    name: 'Charges',
    type: 'link',
    icon: 'payments'
  },
  {
    state: 'hometime',
    name: 'Hometime',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'users',
    name: 'Users',
    type: 'link',
    icon: 'groups'
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
