import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { AppBlankComponent } from './components/layouts/blank/blank.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';
import { DemoMaterialModule } from './demo-material-module';
import { ErrorComponent } from './authentication/error/error.component';
import { ErrorInterceptor, HeaderInterceptor, ProgressIndicatorInterceptor, ResponseInterceptor } from './core/services/interceptors';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpCacheInterceptorModule.forRoot({
      strategy: 'implicit',
      ttl: 60000
    }),
    NgxPermissionsModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressIndicatorInterceptor, multi: true },
  ]
})
export class AppModule { }
