<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->

<router-outlet></router-outlet>

<footer id="blank">
  © Weltlink, {{year}}.
  <pre style="position: absolute; bottom: 0; right: 20px; margin: 0;">{{buildNo}}</pre>
</footer>
