import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'app/authentication/auth';
import { ProgressIndicatorService } from '../misc';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private snackbar: MatSnackBar,
    private auth: AuthService,
    private requestCount: ProgressIndicatorService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      this.handleErrors(req, error);
      return throwError(error);
    }));
  }

  handleErrors(request: HttpRequest<any>, error: HttpErrorResponse) {
    if (!window.navigator.onLine) { return this.openErrorSnackbar('The internet connection appears to be offline.'); }

    console.log('erroooor =>', error, '/n', 'request => ', request);
    if (error.status === 403) {
      return this.openErrorSnackbar(
        'Forbidden',
        'Access to this location is denied'
      );
    }
    if (error.status === 404) {
      return this.openErrorSnackbar(
        '404 :(',
        'The requested resource is not found'
      );
    }

    if (error.status === 413) {
      return this.openErrorSnackbar(
        '413 :(',
        'Payload is too large'
      );
    }

    if (error.status === 500) {
      return this.openErrorSnackbar(
        '500',
        'Internal server error. Our development team has been notified. Thank you for your patience.'
      );
    }

    if (error.status === 401) {
      // this.lastRouteAPI.recordLastURL();
      this.auth.logout();
      return this.openErrorSnackbar('401', 'Unauthorized. Navigating to login');
    }

    if (error.status === 400) {
      return this.openErrorSnackbar(
        '400',
        error.error[Object.keys(error.error)[0]]
      );
    }

    return this.openErrorSnackbar('An unknown error occured.');
  }

  openErrorSnackbar(title: string, text: string = '') {
    this.snackbar.open(title + ' ' + text, 'Copy message', {
      duration: 5000,
      panelClass: ['warn-snackbar'],
    }).onAction().subscribe(() => {
      const el: HTMLTextAreaElement = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.snackbar.open('The error message was copied to the clipboard.', undefined, { duration: 1000 });
    });
  }
}
