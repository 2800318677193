import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from 'app/demo-material-module';
import { ConfirmDialogModule } from '@app/components/custom/confirm-dialog/confirm-dialog.module';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { UtcDatePipe } from './pipes/utc-date-pipe.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { DeleteButtonDirective, WtkTableDirective, NgModelChangeDebouncedDirective } from './directives';
import { DeleteButtonComponent } from './views';
import { NgxPermissionsModule } from 'ngx-permissions';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { MatButtonLoadingDirective } from './directives/mat-button-loading.directive';

const PAGESIZE_MULTIPLIER = 25

export const PAGINATOR_DEFAULTS = {
  formFieldAppearance: 'standard',
  pageSize: PAGESIZE_MULTIPLIER,
  pageSizeOptions!: [...Array(3)].map((x, i) => PAGESIZE_MULTIPLIER * (i + 1))
}

@NgModule({
  imports: [
    CommonModule,
    MatMomentDateModule,
    DemoMaterialModule,
    NgxPermissionsModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    NgModelChangeDebouncedDirective,
    DeleteButtonDirective,
    DeleteButtonComponent,
    RemoveUnderscorePipe,
    UtcDatePipe,
    WtkTableDirective,
    WithLoadingPipe,
    MatButtonLoadingDirective
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    NgModelChangeDebouncedDirective,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    DemoMaterialModule,
    ConfirmDialogModule,
    RemoveUnderscorePipe,
    UtcDatePipe,
    DeleteButtonDirective,
    DeleteButtonComponent,
    NgxPermissionsModule,
    WtkTableDirective,
    WithLoadingPipe,
    MatButtonLoadingDirective
  ],
  providers: [
    { provide: DatePipe, useClass: UtcDatePipe },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: PAGINATOR_DEFAULTS
    },
    MenuItems,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class SharedModule { }
