import { Routes } from '@angular/router';
import { AppBlankComponent } from '@app/layout/blank/blank.component';
import { ErrorComponent } from './authentication/error/error.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'login',
        redirectTo: 'authentication',
        pathMatch: 'full'
      },
      {
        path: 'authentication',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: '404',
        component: ErrorComponent
      }
    ]
  }, {
    path: '',
    loadChildren: () => import('./pages/main.module').then(m => m.MainModule)
  }, /* {
    path: '**',
    redirectTo: '/404'
  } */
];
