import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { UserService } from '@core';
import { of } from 'rxjs';
import { CoreHTTPService } from 'app/core/services/base';
import { tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CoreHTTPService {

  constructor(
    protected http: HttpClient,
    private router: Router,
    private permissions: NgxPermissionsService
    // private userAPI: UserService,
  ) {
    super(http);
    if (!(this.username || this.userFullName)) this.logout()
    if (this.role) this.permissions.addPermission(this.role)
  }

  login(username: string, password: string) {
    return this.post('auth/authenticate', { username, password }).pipe(tap(result => {
      this.token = result.token;
      this.userFullName = result.user;
      this.username = result.user.username;
      this.role = result.user.role;
      this.permissions.addPermission(result.user.role)
    }));
  }

  set username(value: string | null) {
    if (value === null) { value = ''; }
    localStorage.setItem('username', value);
  }

  get username(): string | null {
    return localStorage.getItem('username');
  }

  set userFullName(value: any | null) {
    if (!value) { value = {first_name: 'Unknown', last_name: 'Name'}; }
    localStorage.setItem('userFullName', `${value.first_name} ${value.last_name}`.trim());
  }

  get userFullName(): any | null {
    return localStorage.getItem('userFullName') || '';
  }

  set role(value: string | null) {
    if (value === null) { value = ''; }
    localStorage.setItem('role', value);
  }

  get role(): string | null {
    return localStorage.getItem('role');
  }

  set token(value: string | null) {
    // tslint:disable-next-line: curly
    if (value === null) value = '';
    localStorage.setItem('token', value);
  }

  get token(): string | null {
    return localStorage.getItem('token');
  }

  logout() {
    this.token = null;
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
